module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"337608664","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Melalogic","short_name":"Melalogic","description":"Melalogic is a platform that gives black people a single source of skin health information from trusted professionals who look like them.","start_url":"/","background_color":"#F5D2A7","theme_color":"#f9efe0","display":"minimal-ui","icon":"src/images/favicon.svg","crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/acne-diet","/acral-lentiginous-melanoma","/basal-cell-carcinoma","/central-centrifugal-cicatricial-alopecia","/chapped-lips","/corns-calluses-warts","/seborrheic-dermatitis-dandruff","/eczema","/excessive-sweating-hyperhidrosis","/eye-protection","/hair-breakage","/hidratenitis-suppurativa","/hyperpigmentation","/laser-treatment","/melanoma","/nail-care","/popping-pimples","/razor-bumps-keloids","/skin-bleaching-lightening","/stretch-marks","/sun-damage","/sunscreen","/traction-alopecia","/vitiligo","/about"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"6c4f13c14603d897476d7e3d305047fe","head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"eventTypes":{"outboundLinkClick":"OUTBOUND_LINK_CLICK","pageView":"PAGE_VIEW"},"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
